body {
  margin: 0;
  padding: 0 !important;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", sans-serif !important;
  background: url("/images/bg-tile.png");
  background-color: #c8cad7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  margin: 0 16px;
  width: 145px;
}

.ml--50 {
  margin-left: -50px;
}

.ml-16 {
  margin-left: 16px !important;
}

#main_toolbar {
  background-color: #fff;
  color: #0c385f;
}

.header-link {
  font-weight: 500;
  color: #0c385f !important;
  text-decoration: none;
}

.header-link button:hover {
  color: #0c385f;
}

.app-version {
  display: flex;
  background: #e0e0e0;
  justify-content: center;
  margin-bottom: -8px;
  padding: 4px;
  font-weight: bold;
}

.profile-menu-container {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.flight-modify-search-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.main-container {
  max-width: 1200px;
  margin-top: 20px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  margin-bottom: 20px;
}

.page-not-found {
  padding: 64px;
  text-align: center;
}

.relative {
  position: relative;
}

.mt-8 {
  margin-top: 8px !important;
}

#passport_url1,
#passport_url2,
#visaurl,
#id_card_file {
  padding: 17px;
  border: 1px solid #c4c4c4;
  margin: 0;
  border-radius: 4px;
}

.upload-id-label {
  position: absolute;
  margin-top: -8px !important;
  left: 16px;
  padding: 0 4px;
  background: #fff;
}

#id_card_file {
  width: 100%;
}

.bb-1 {
  border-bottom: 1px solid #ddd;
  margin-top:10px
}

.sidebar-content {
  width: 175px;
}

.sidebar-content > ul {
  padding: 0;
}

.sidebar-content > ul > a {
  border-bottom: 1px solid #ddd;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.vertical-bottom {
  vertical-align: bottom !important;
}

.vertical-middle {
  vertical-align: middle !important;
}

.info-icon {
  margin-left: 12px;
  font-size: 18px !important;
  vertical-align: middle;
}

.expansion-panel {
  padding: 16px;
  cursor: pointer;
  margin-bottom: 8px;
}

.expansion-panel.open {
  transition: all 0.2s ease;
  margin: 8px 0 16px 0 !important;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.exclusive-border {
  border-left: 3px solid #f44336;
}

.filters {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.filters div button {
  background-color: rgba(33, 150, 243, 0.12);
}

.filters .depart-time > div {
  background-color: rgba(33, 150, 243, 0.12);
  cursor: pointer;
  width: 25%;
  display: inline-block;
  text-align: center;
  border-right: 1px solid #d5ddf7;
  box-sizing: border-box;
  color: #21598c;
  padding: 8px;
}

.filters > div > legend {
  margin: 20px 0 10px;
}

.filters > div {
  padding-bottom: 30px;
  border-bottom: 1px solid #ddd;
}

.filters-dialog > div > div,
.fare-rules-dialog > div > div,
.tc-dialog > div > div,
.trip-dialog > div > div,
.expense-report-submission > div > div,
.flight-seat-dialog > div > div,
.other-fares-dialog > div > div {
  min-width: 800px;
  max-height: 100% !important;
}

.filters-dialog > div,
.fare-rules-dialog > div,
.tc-dialog > div,
.trip-dialog > div,
.expense-report-submission > div,
.flight-seat-dialog > div,
.other-fares-dialog > div {
  min-width: 800px;
  max-height: 100% !important;
}

.date-scroller {
  flex-wrap: nowrap !important;
  overflow: auto;
  padding-bottom: 5px;
  margin: 8px 0 !important;
}

.date-scroller > div {
  padding: 4px !important;
}

progress {
  position: relative;
  width: 100%;
  height: 6px !important;
  background-color: rgba(255, 87, 34, 0.3);
  vertical-align: top;
}

progress::-webkit-progress-bar {
  background-color: rgba(255, 87, 34, 0.3);
}

progress[value]::-webkit-progress-value {
  background: rgba(255, 87, 34, 1);
  background: -moz-linear-gradient(
    left,
    rgba(255, 87, 34, 1) 0%,
    rgba(241, 111, 92, 1) 0%,
    rgba(246, 41, 12, 1) 0%,
    rgba(240, 47, 23, 1) 0%,
    rgba(248, 80, 50, 1) 20%,
    rgba(248, 80, 50, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(255, 87, 34, 1)),
    color-stop(0%, rgba(241, 111, 92, 1)),
    color-stop(0%, rgba(246, 41, 12, 1)),
    color-stop(0%, rgba(240, 47, 23, 1)),
    color-stop(20%, rgba(248, 80, 50, 1)),
    color-stop(100%, rgba(248, 80, 50, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 87, 34, 1) 0%,
    rgba(241, 111, 92, 1) 0%,
    rgba(246, 41, 12, 1) 0%,
    rgba(240, 47, 23, 1) 0%,
    rgba(248, 80, 50, 1) 20%,
    rgba(248, 80, 50, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 87, 34, 1) 0%,
    rgba(241, 111, 92, 1) 0%,
    rgba(246, 41, 12, 1) 0%,
    rgba(240, 47, 23, 1) 0%,
    rgba(248, 80, 50, 1) 20%,
    rgba(248, 80, 50, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(255, 87, 34, 1) 0%,
    rgba(241, 111, 92, 1) 0%,
    rgba(246, 41, 12, 1) 0%,
    rgba(240, 47, 23, 1) 0%,
    rgba(248, 80, 50, 1) 20%,
    rgba(248, 80, 50, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 87, 34, 1) 0%,
    rgba(241, 111, 92, 1) 0%,
    rgba(246, 41, 12, 1) 0%,
    rgba(240, 47, 23, 1) 0%,
    rgba(248, 80, 50, 1) 20%,
    rgba(248, 80, 50, 1) 100%
  );
}

.progress-value {
  position: absolute;
  width: auto;
  height: auto;
  padding: 3px 6px;
  background-color: #f85032;
  font-size: 10px;
  top: 7px;
  border-radius: 4px;
  color: white;
  font-weight: 90;
}

.sort-icon {
  width: 16px !important;
  height: 16px !important;
  vertical-align: middle;
  color: #f50057 !important;
}

.sort-default {
  width: 16px !important;
  height: 16px !important;
  vertical-align: middle;
  color: grey !important;
}

.clear-filters {
  float: right;
  margin-top: -10px !important;
  padding: 4px !important;
}

.bus-modify-search .tg-search {
  padding: 0 !important;
}

.verified-user {
  color: green;
  font-weight: 600 !important;
}

.text-right {
  text-align: right;
}

.right {
  float: right;
}

.caption {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 0.75rem;
  font-weight: 400;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", sans-serif !important;
  line-height: 1.375em;
}

.capitalize {
  text-transform: capitalize;
}

.xs-svg-middle svg {
  vertical-align: middle !important;
  margin-right: 4px;
}

.price {
  color: #2e7d32 !important;
}

.bold {
  font-weight: 700 !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

.flight-title {
  margin-bottom: 10px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.flight-title svg,
svg.rotate-90 {
  vertical-align: bottom;
}

.supplier-img {
  width: 80%;
}

.other-fare {
  padding: 8px 16px !important;
  position: relative;
}

.alt-bg {
  background-color: #f3f3f3;
}

.alt-bg > div,
.other-fare > div {
  padding: 4px !important;
}

.sup-img {
  width: 135px;
}

.flight-info {
  padding: 16px 0 !important;
  text-align: center;
}

.other-info button {
  padding: 0 !important;
  margin-top: -8px !important;
}

.fare-rules {
  padding: 10px 0;
  margin-top: 16px;
}

.change-over-msg {
  background: #fffcc7;
  border: 1px solid #ffc107;
  border-radius: 10px;
  padding: 8px;
  display: flex;
}

.expansion-div {
  font-size: 14px;
  cursor: pointer;
  margin-top: 32px;
  border: 1px solid #f5f5f5;
}

.expansion-header {
  display: flex;
  padding: 12px;
  position: relative;
  background: #f5f5f5;
}

.expansion-header > div {
  margin-right: 16px;
}

.expansion-header button {
  position: absolute;
  right: 0;
  padding: 0;
}

.expansion-body {
  padding: 16px;
  margin-top: 24px;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100% !important;
}

.mdi-3x {
  font-size: 3rem !important;
  margin-bottom: 16px;
}

.booking-header-title {
  margin: 21px 0 !important;
}

.hotel-booking-header-title {
  margin: 16px 0 !important;
}

.headerIcon {
  border: 1px solid;
  border-radius: 50%;
  padding: 2px;
  vertical-align: middle;
}

.bp-selected-sn {
  color: #ff5722;
}

.cab-image {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cab-image > img {
  width: 250px;
}

.cab-details {
  display: flex;
}

.cab-details > div:nth-child(2) {
  margin-left: 24px;
}

.cab-booking-btn {
  margin: 24px;
  text-align: center;
}

.hotel-policy {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  font-weight: bold;
}

.hotel-policy.in {
  background: #009688;
}

.hotel-policy.out {
  background-color: red;
}

.hcard {
  margin: 8px 0;
  box-shadow: none !important;
  border: 1px solid #d0cece;
  max-height: 256px;
  overflow: hidden;
  position: relative;
}

.hcard .section {
  padding: 8px 16px;
}

.hcard .imageSection {
  overflow: hidden;
  background: #e0e0e0;
  height: 256px;
}

.carousel.carousel-slider {
  height: 338px;
}

.carousel .thumbs {
  padding: 0;
}

.carousel.carousel-slider .control-arrow {
  background: transparent !important;
}

.carousel .thumbs-wrapper {
  margin: 0 !important;
}

.carousel img {
  width: auto !important;
  height: 338px;
}

.carousel .thumb img {
  width: 100% !important;
  height: 70px !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #bf360c !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #bf360c !important;
}

.carousel .slide.selected {
  border: none;
}

.hotel-view,
.hotel-ticket,
.review-hotel,
.padding-16 {
  padding: 16px !important;
}

.hotel-policy-out {
  margin-top: 32px;
  background-color: #ff8a65;
  color: #fff;
  padding: 8px;
  text-align: center;
  font-weight: bold;
  border-radius: 4px;
  width: 150px;
}

.hotel-policy-in {
  margin-top: 32px;
  background-color: #4db6ac;
  color: #fff;
  padding: 8px;
  text-align: center;
  font-weight: bold;
  border-radius: 4px;
  width: 150px;
}

.hotel-review .hotel-policy {
  top: 0;
  right: 0;
  left: auto;
  bottom: auto;
}

.padding-8 {
  padding: 8px !important;
}

.hotel-ticket .hotel-img {
  width: 170px;
}

.secondary-bg {
  background-color: rgba(255, 61, 0, 0.08) !important;
}

.hotel-fare-details-container {
  padding: 12px;
}

.hotel-booking-btn-container {
  text-align: center;
  margin: 24px 0 !important;
}
.p-12 {
  padding: 12px !important;
}

.reward-points {
  background: #ff7043;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.reward-points > span:first-child {
  padding: 6px;
}

.reward-points > span:last-child {
  padding: 6px;
  background: #bf360c;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.reward-points svg {
  font-size: 16px;
  vertical-align: text-top;
}

.reward-points-text {
  color: #2e7d32;
  font-weight: bold;
  font-size: 13px;
}

.reward-points-text svg {
  font-size: 16px;
  vertical-align: sub;
  color: #ff7043;
}

.rewards-count {
  color: green;
  font-weight: 600;
  font-size: 20px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bg-gray {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.hotel-traveller-details {
  padding: 12px !important;
}

.edit-btn {
  position: absolute !important;
  right: 16px;
}

.operator_pnr {
  word-break: break-all;
}

.profile-edit-drawer > div:nth-child(3),
.visa-create-drawer > div:nth-child(3),
.passport-add-edit-drawer > div:nth-child(3),
.ticket-resc-dialog > div:nth-child(3),
.ticket-cancel-dialog > div:nth-child(3),
.ticket-resc-drawer > div:nth-child(3),
.bus-cancel-drawer > div:nth-child(3) {
  width: 55%;
  padding: 16px;
}

.train-moblie-filter-drawer > div:nth-child(3),
.hotel-mobile-search-drawer > div:nth-child(3),
.tvl-select-drawer > div:nth-child(3) {
  width: 45%;
  padding: 16px;
}

.flight-image-40 {
  height: auto !important;
  border-radius: 0 !important;
  width: 40px !important;
}

.flight-image {
  height: auto !important;
  border-radius: 0 !important;
  width: 50px !important;
}

.flt-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flt-img > div,
.flt-img img {
  width: 50px;
  height: auto;
  border-radius: 0;
}

.bottom-container-1 {
  background-color: #000;
  height: 70px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.white {
  color: #fff !important;
}

.airportName {
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 0px !important;
}

.airport-full-name {
  color: #222 !important;
  font-size: 12px;
}

.airport {
  padding: 8px 12px;
}

.airport:hover,
.airport-focused {
  background: #deebff;
}

.round-trip-card {
  padding: 12px !important;
}

.right-aligned {
  position: absolute;
  right: -8px;
  bottom: 0;
  color: #a6a6a6;
}

.justify-center {
  justify-content: center;
  display: flex !important;
}

.fs-16 {
  font-size: 16px !important;
  font-weight: 700;
}

.bottom-bar p {
  font-size: 1rem !important;
  line-height: 1.5em !important;
  color: #fff !important;
  display: inline-table;
  margin: 0;
}

.bottom-bar p .material-icons {
  vertical-align: top;
  margin: 0 16px;
}

.bottom-container {
  background-color: #000;
  height: 78px;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #fff !important;
  padding: 8px 24px;
}

.right-border {
  border-right: 1px solid #fff !important;
}

.ticket-logo {
  width: 125px;
  padding: 6px;
}

.ticket-flight-details {
  padding: 20px 0;
}

.ticket-airline-details {
  margin-top: 30px;
}

.bus-right-info {
  display: flex;
  flex-direction: row;
}

.bus-ticket-status {
  margin-right: 24px;
  padding: 4px 16px;
  background: #f5f5f5;
}

.bust-ticket-status {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

.bus-pending {
  color: #ff5722;
}

.bus-confirm,
.bus-confirmed {
  color: green;
}

.bus-refunded {
  color: green;
}

.bus-partiallyticketed {
  color: #03a9f4;
}

.a-btn {
  color: #ff5721;
  text-decoration: none;
}

.remove-seat {
  padding: 0 !important;
  min-width: 24px !important;
  background: #ebebeb !important;
  height: 28px;
}

.search-item-list {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #f5f5f5;
}

.search-item {
  padding: 16px;
  cursor: pointer;
  border: 1px solid #f5f5f5;
}

.rotate-45 {
  transform: rotate(45deg);
}
.v-middle {
  vertical-align: middle;
}

.tg-search {
  padding: 24px 16px 0px;
  margin-bottom: 16px;
}

.search-container {
  padding: 0 0 !important;
}

.journey-type-container {
  background-color: #f5f5f5;
  padding: 8px 16px;
}

.journey-type-container .journey-type {
  flex-direction: row;
}

.journey-type-container .journey-type > label > span,
.cab-trip-type > div > label > span {
  font-weight: 700;
}

.tg-search #origin_airport > div:nth-child(3),
.tg-search #destination_airport > div:nth-child(3),
#pref_class > div:nth-child(3),
#fare_type > div:nth-child(3),
#hotel_city > div:nth-child(3),
#travelling_from > div:nth-child(3),
#travelling_to > div:nth-child(3),
#from_station > div:nth-child(3),
#to_station > div:nth-child(3),
#cab_pickup_airport > div:nth-child(3),
#cab_drop_area > div:nth-child(3),
#cab_pickup_location > div:nth-child(3),
#cab_drop_airport > div:nth-child(3),
#start_time > div:nth-child(3),
#start_location > div:nth-child(3),
#select_package > div:nth-child(3),
#meeting_location > div:nth-child(3),
#train_quota > div:nth-child(3) {
  z-index: 99;
  margin: 0;
}

.tg-search #origin_airport > div > div,
.tg-search #destination_airport > div > div,
#pref_class > div > div,
#fare_type > div > div,
#hotel_city > div > div,
#travelling_from > div > div,
#travelling_to > div > div,
#from_station > div > div,
#to_station > div > div,
#cab_pickup_airport > div > div,
#cab_drop_area > div > div,
#cab_pickup_location > div > div,
#cab_drop_airport > div > div,
#start_time > div > div,
#start_location > div > div,
#select_package > div > div,
#meeting_location > div > div,
#train_quota > div > div {
  font-weight: 600;
  font-size: 16px;
  padding: 11px;
}

.tg-search .Select-menu-outer {
  z-index: 99;
}

.tg-search .DayPickerInput {
  width: 100%;
}

.tg-search .DayPickerInput .DayPickerInput-Overlay {
  z-index: 10;
}

.tg-search .DayPickerInput input {
  width: 100%;
  height: 60px;
  padding: 16px 10px;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.tg-search .DayPickerInput input:disabled {
  background-color: #f5f5f5;
}

.tg-search label.required {
  color: #716d6d;
  position: absolute;
  top: 0px;
  z-index: 9;
  left: 16px;
  font-size: 12px;
  padding: 0px 7px;
  background-color: #fff;
}

.tg-search .swap-data {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swap-data button {
  background-color: #fff;
}

.fare-type {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  height: 42px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.fare-type-theme {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  height: 42px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.fare-type.active,
.fare-type:hover,
.fare-type-btn.active,
.fare-type-btn:hover {
  background-color: #ff8b64;
  color: #fff;
}

.room-flex {
  display: flex;
  border: 1px solid #ddd;
  justify-content: space-between;
}

.room-flex > div:first-child,
.room-flex > div:last-child {
  padding: 12px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.room-flex > div:first-child:hover,
.room-flex > div:last-child:hover {
  background-color: #e5e5e5;
}

.room-flex > div:nth-child(2) {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.airport-swapper-btn {
  position: absolute;
  right: -19px;
  top: 16px;
  z-index: 9;
}

.airport-swapper-btn button {
  background-color: #ff8a65;
}

.airport-swapper-btn button svg {
  color: #fff;
}

.flight-class-container {
  margin-top: 8px;
  margin-bottom: 8px;
}

.flight-class-container label {
  color: #716d6d;
  font-size: 12px;
}

.flight-class {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cab-trip-type > div {
  flex-direction: row;
  justify-content: space-between;
}

.cab-trip-type {
  background: #f5f5f5;
  padding: 8px 16px;
}

@media screen and (max-width: 599px) {
  .search-item-list {
    flex-direction: row;
  }
}

#search_btn_container {
  text-align: center;
  margin: 24px 0;
}

.ad-container {
  background-image: url(/images/choozofyslider.png);
}

.travel-tabs-container,
.travel-tabs-container > div {
  height: 594px;
  padding: 0 !important;
}

.mobile-search-drawer > div:nth-child(3) {
  width: 45%;
}

.defence_options_dialog > div:nth-child(3) > div {
  min-width: 500px;
}

.id-card-image > img {
  width: 300px;
  border: 1px solid #9e9e9e;
}

.exclusive-price {
  color: #fff !important;
  background: #f44336;
  font-weight: bold !important;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  padding: 3px 10px;
  position: absolute;
  left: -12px;
}

.defence-price {
  color: #fff !important;
  background: #f44336;
  font-weight: bold !important;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  padding: 3px 10px;
  position: absolute;
  left: -12px;
}

.fare-type_custom {
  color: #fff;
  background: #4caf50;
  padding: 4px 12px;
  border-radius: 4px;
  font-weight: 700;
}

.flight-traveller-grid {
  margin-top: 8px !important;
}

.airports-search-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid #ddd;
}

.airports-search-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #b5b4b4;
}

.airport-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.airport-details .airport-val {
  background: #ddd;
  padding: 3px 8px;
  font-weight: 700;
  border-radius: 4px;
}

.selected-airport {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px;
}

.selected-airport > div:first-child {
  font-weight: 700;
  font-size: 16px;
}

.login-to-autofill {
  position: absolute !important;
  right: 0;
  top: -2px;
}

.pick-tvl {
  display: inline-block !important;
  text-align: left;
  font-weight: bold !important;
}

.sup-mat-btn {
  cursor: pointer;
  margin-right: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.sup-mat-btn > span {
  margin-left: 8px;
}

.sup-mat-btn svg {
  font-size: 16px;
}
.support-matrix-row {
  border: 1px solid #ddd;
  padding: 16px;
  margin: 16px 0;
}

.support-matrix-row .support {
  display: flex;
  justify-content: space-between;
  margin: 8px 0 16px;
}

.support-matrix-dailog > div:nth-child(3) {
  width: 30%;
  padding: 16px;
}
.sup-mat-close {
  float: right;
}

.flight-booking-login > div:nth-child(3),
.add-personal-traveller-dialog > div:nth-child(3) {
  width: 35%;
  padding: 16px;
}

.login-dialog {
  max-width: 450px;
  margin: 0 auto;
}

.login-close-btn {
  width: 80px;
  float: right;
}

.error {
  color: #ed1c24 !important;
}

.title-margin {
  margin: 16px 0 !important;
}

.traveller-divider {
  border-bottom: 1px solid #ddd;
  margin-bottom: 8px !important;
}

.map-dialog > .filter-div {
  position: absolute;
  right: 24px;
  left: 24px;
  z-index: 1;
  top: 20px;
}

.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.m-l-16 {
  margin-left: 16px !important;
}

.promo-success-msg {
  color: green;
  font-weight: 600 !important;
}

.header-back-icon-btn {
  display: none !important;
}

@media screen and (max-width: 599px) {
  .header-back-icon-btn {
    position: fixed !important;
    top: 5px;
    background-color: #fff !important;
    z-index: 9999;
    display: block !important;
  }
}

.swap-airports {
  transform: translate(-50%);
  position: absolute;
  left: 50%;
}

.swap-airports > button {
  margin-top: 15px;
}

.airfee-promotion-text {
  background-color: #ffecb3;
  /* background-color: #ffecb3; #c8e6c9; */
  color: #4a4a4a;
  font-size: 10px;
  text-align: left;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  text-align: center;
}
