.travel-day {
    margin: 8px 0 !important;
  }
  
  .flight-card-mini {
    padding: 16px 0;
  }
  
  .flight-img {
    vertical-align: middle;
    margin-right: 12px;
  }
  