.only-print {
  display: none;
}

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 1em;
    font-size: 0.8em;
  }

  .ticket-logo {
    background-color: #0c385f !important;
    padding: 6px !important;
  }

  .fc-widget-normal {
    display: none;
  }

  .print-btn,
  .no-print,
  header {
    display: none !important;
  }

  .print-al {
    width: 33%;
    flex-basis: unset !important;
  }

  .only-print {
    display: block;
  }

  .print-inline-block {
    float: left !important;
  }

  .print-passengers-table {
    float: left !important;
  }
}
