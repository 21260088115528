@media screen and (min-width: 601px) and (max-width: 959px) {
  .calendar-cell-style {
    width: 96px;
  }

  .main-container > div {
    width: 100% !important;
    margin: 0 !important;
  }

  .fixed-md-lg {
    position: relative;
    width: auto;
  }

  .fixed-md-lg > div {
    position: relative;
  }

  .search-filter-container {
    background-color: #005e9f;
    padding: 5px;
  }

  .mobile-up-filter {
    margin-top: -5px;
    float: right;
    color: #fff !important;
  }

  .hotel-mobile-filter {
    float: right;
    color: #fff !important;
  }

  .mobile-filter {
    float: right;
    color: #fff !important;
  }

  .flt-img {
    vertical-align: bottom;
  }
}

@media screen and (max-width: 600px) {
  .travel-tabs-container {
    height: auto !important;
    margin: 0px !important;
  }

  .user-ratings-and-reviews > div {
    flex-direction: column;
  }

  .sup-mat-btn {
    margin-right: 0;
  }

  .support-matrix-dailog > div:nth-child(3),
  .add-personal-traveller-dialog > div:nth-child(3) {
    width: 100%;
  }

  .support-matrix-row .support {
    display: flex;
    flex-direction: column;
  }

  .pick-tvl {
    position: absolute !important;
    right: 0;
    top: -1px;
    text-align: center;
    font-weight: bold;
  }

  .fare-types-btn-bar {
    display: flex;
    justify-content: space-between;
  }

  .fare-type-btn {
    border: 1px solid #c4c4c4;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    flex: 1;
    margin-right: 8px;
    text-align:center;
  }

  .fare-type-btn:last-child {
    margin-right: 0;
  }

  .fare-type-btn:hover {
    background-color: #ff8b64;
  }

  .traveller-type {
    background: #f2f2f2;
    margin-bottom: 8px !important;
    text-align: center;
  }

  .defence_options_dialog > div:nth-child(3) > div,
  .flight-booking-login > div:nth-child(3),
  .airports-search-drawer > div:nth-child(3) {
    min-width: 100%;
  }

  .travel-tabs-container > div {
    height: auto !important;
  }

  .ad-container {
    background-image: none !important;
  }

  .padding-16,
  .hotel-ticket {
    padding: 12px !important;
  }

  .xs-img-div {
    display: flex;
    justify-content: center;
  }

  .time-info {
    justify-content: space-between !important;
  }

  .xs-left {
    text-align: left;
  }

  .cancellation-btn {
    padding: 0 !important;
    margin-top: 8px !important;
  }

  .cancellation-policy {
    margin-top: 0 !important;
  }

  .seat-count {
    font-size: 14px !important;
  }

  .round-trip-card .text-center {
    text-align: right !important;
  }

  .xs-mb-100 {
    margin-bottom: 100px;
  }

  .xs-img-div img {
    width: 30px;
  }

  .bus-ticket-status {
    padding: 4px 8px;
  }

  .journey-type {
    flex-direction: row !important;
  }

  .register-page,
  .landing-page {
    height: auto !important;
  }

  .flight-image {
    width: 40px !important;
  }

  .xs-hotel-address {
    word-break: break-all;
    white-space: normal !important;
  }

  .flight-traveller-grid {
    padding: 8px;
  }

  .bus-travellers-details > div,
  .flight-traveller-grid > div,
  .hotel-traveller-details > div {
    padding: 4px !important;
  }

  .lower-deck {
    padding: 4px;
  }

  .xs-traveller {
    margin-top: 16px;
  }

  .bus-seat > svg {
    width: 16px;
  }

  .bus-seat,
  .seat-empty {
    width: 16px !important;
    height: 18px !important;
    margin: 2.5px !important;
  }

  .seat-empty {
    border: 1px solid #fff;
  }

  .seat-bed {
    width: 36px !important;
  }

  .pl-24 {
    padding-left: 0 !important;
  }

  .expansion-panel,
  .departing-arrival-flight {
    padding: 12px !important;
  }

  .flight-card-mini {
    padding: 0 !important;
  }

  #email_ticket_id > div:nth-child(2) > div {
    width: 300px;
    min-width: 300px;
  }

  /* .DayPickerInput .DayPickerInput-Overlay,
  .DayPickerInput .DayPickerInput-Overlay .DayPicker {
    width: 100%;
  }

  .hotel-check-in .DayPickerInput-Overlay,
  .hotel-check-out .DayPickerInput-Overlay {
    width: 100% !important;
  } */

  .roundtrip-policy {
    min-width: 74px;
  }

  .xs-m-b-8 {
    margin-bottom: 8px;
  }

  .logo {
    width: 105px;
    margin-left: -12px;
  }

  .sidebar-content .logo {
    margin-left: 16px;
  }

  .left {
    float: left !important;
    justify-content: left !important;
  }

  .xs-max-dialog-height {
    max-height: 400px !important;
    overflow: auto;
  }

  .fare-breakup {
    width: 100%;
  }

  .mobile-up-filter {
    margin-top: -5px;
    float: right;
    color: #fff !important;
  }
  .search-filter-container {
    background-color: #005e9f;
    padding: 5px;
  }
  .no-shadow-xs {
    box-shadow: none !important;
  }
  .fixed-md-lg {
    position: relative;
    width: auto;
  }

  .fixed-md-lg > div {
    position: relative;
  }
  .flight-layout {
    zoom: 0.9;
  }

  .padding-heading {
    padding: 16px !important;
  }
  .sup-img {
    width: 70px;
  }

  .other-fare {
    padding: 8px !important;
  }
  .carousel.carousel-slider {
    height: inherit;
  }
  .hotel-mobile-filter {
    float: right;
    color: #fff !important;
  }

  .hcard {
    max-height: inherit;
  }
  .mobile-filter {
    float: right;
    color: #fff !important;
  }

  .btn-bar {
    width: 100% !important;
  }

  .mobile-search-drawer > div:nth-child(3) {
    padding: 16px;
  }

  .passport-add-edit-drawer > div:nth-child(3),
  .bus-cancel-drawer > div:nth-child(3),
  .profile-edit-drawer > div:nth-child(3),
  .visa-create-drawer > div:nth-child(3),
  .ticket-resc-dialog > div:nth-child(3),
  .ticket-cancel-dialog > div:nth-child(3),
  .ticket-resc-drawer > div:nth-child(3),
  .drawer > div,
  .filters-dialog > div,
  .flight-seat-dialog > div > div,
  .filters-dialog > div > div,
  .fare-rules-dialog > div > div,
  .tc-dialog > div > div,
  .trip-dialog > div > div,
  .other-fares-dialog > div > div,
  .fare-rules-dialog > div,
  .flight-seat-dialog > div,
  .confirm-dialog > div,
  .hotel-cancellation-policy > div,
  .other-fares-dialog > div,
  .edit-profile-drawer > div:nth-child(2),
  .seat-meal-preference-drawer > div:nth-child(2),
  .mobile-search-drawer > div:nth-child(3),
  .train-moblie-filter-drawer > div:nth-child(3),
  .hotel-mobile-search-drawer > div:nth-child(3),
  .tvl-select-drawer > div:nth-child(3) {
    min-width: 100%;
  }

  .tg-search {
    padding-top: 16px;
  }
  
  .mobile-search-drawer .tg-search,
  .hotel-mobile-search-drawer .tg-search {
    padding: 0;
  }

  .calendar-cell-style {
    width: 35px;
    height: 50px;
  }

  .auth-container,
  .hotel-search-results-page {
    width: 100% !important;
  }

  .oneway-header {
    padding-left: 0;
  }

  .booking-header-title {
    margin: 12px 0 12px !important;
  }

  .hide-xs {
    display: none;
  }

  .round-trip-card {
    padding: 8px !important;
  }
  .one-way-es {
    border-bottom: 1px solid #ddd;
  }

  .one-way-es > div > div {
    padding-right: 0 !important;
  }

  .xs-left {
    justify-content: left !important;
  }

  .bottom-container {
    padding: 12px;
  }

  .main-container,
  .bottom-container .main-container {
    margin: 0 !important;
  }

  .right-aligned {
    right: -8px !important;
    bottom: -9px !important;
  }

  .main-container > div {
    width: 100% !important;
    margin: 0 !important;
  }

  .flt-img-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flt-img-row > img {
    width: 50px;
  }

  .flt-img {
    vertical-align: bottom;
  }

  .rt-flight-details {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .change-over-msg {
    margin: 8px 0;
  }

  #menu-mealPref div:nth-child(2) li {
    white-space: normal;
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
  }

  #menu-mealPref li span.price {
    width: 105px;
  }

  .xs-m-t--15 {
    margin-top: -15px !important;
  }

  .oneway-bottom-bar > div {
    margin-top: -16px !important;
    padding: 4px !important;
  }

  .top-right {
    top: 25px;
  }

  .filters {
    height: 100vh;
    overflow: auto;
  }

  .filter-drawer-bottom {
    border-top: 1px solid #a7c1d8;
  }

  .filter-drawer-bottom .b-r {
    border-right: 1px solid #a7c1d8;
  }

  .add-tp-open > div {
    min-width: 100%;
  }
  .approval-add-form {
    width: 100%;
    min-width: 100%;
    max-height: 100%;
  }
  .xs-btn {
    width: 32px !important;
    height: 32px !important;
  }

  .xs-full-width {
    margin: 8px 0 !important;
    width: 100%;
  }
  .xs-m-l--4 {
    margin-left: -4px !important;
  }
  .xs-m-l--22 {
    margin-left: -22px !important;
  }
  .xs-m-l--6 {
    margin-left: -6px !important;
  }
  .xs-m-l-12 {
    margin-left: 12px !important;
  }
  .btn-link {
    padding: 0 !important;
  }

  .xs-mt-20 {
    margin-top: 20px !important;
  }

  .ticket-details-btn {
    padding: 0 !important;
    margin-top: 8px !important;
  }

  .id-card-image > img {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .DayPickerInput > div {
    font-size: 14.5px !important;
  }
}

@media screen and (max-width: 340px) {
  .DayPickerInput > div {
    font-size: 11.5px !important;
  }
}
